import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import Blog1 from "../components/img/blog/1"

import BtnWrap from "../components/btn-wrap"
import ImgCon from "../components/img-con"
import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Tropické cyklóny vlivem klimatické změny mění místa výskytu" />
    <h1>Tropické cyklóny vlivem klimatické změny mění místa výskytu</h1>
    <Blog1/>
    <br />
    <p>Tropick&eacute; cykl&oacute;ny, nebo tak&eacute; hurik&aacute;ny, tajfuny či willy-willy. N&aacute;zev se měn&iacute; podle toho, kde tento obrovsk&yacute; v&iacute;r vznikaj&iacute;c&iacute; nad tepl&yacute;m oce&aacute;nem pr&aacute;vě zaplavuje a likviduje lidsk&aacute; s&iacute;dla. Letos vydan&aacute; studie tvrd&iacute;, že klimatick&aacute; změna měn&iacute; m&iacute;sta jejich v&yacute;skytu. Z&aacute;roveň se d&aacute; předpokl&aacute;dat, že počet tropick&yacute;ch cykl&oacute;n bude do budoucna klesat, ale jejich intenzita a ničivost bude vy&scaron;&scaron;&iacute;.</p>

    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Ec59C4LGYb8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <hr /><h2>V&iacute;ce cykl&oacute;nů v severn&iacute;m Atlantiku a centr&aacute;ln&iacute;m Pacifiku</h2>
    <p>Na datov&eacute; řadě mezi lety 1980 a 2018 zjistil <a href="https://orcid.org/0000-0002-1816-3452">Hiroyuki Murakami</a> a kolektiv, že v&yacute;znamně poklesl počet tropick&yacute;ch cykl&oacute;nů v jižn&iacute; oblasti Indick&eacute;ho oce&aacute;nu a z&aacute;padn&iacute; č&aacute;sti Tich&eacute;ho oce&aacute;nu. Naopak v severn&iacute;m Atlantiku a centr&aacute;ln&iacute;m Pacifiku se jejich počet zv&yacute;&scaron;il.</p>
    <p>Je velmi nepravděpodobn&eacute;, že by za pozorovan&eacute; změny v geografick&eacute;m rozložen&iacute; tropick&yacute;ch cykl&oacute;n mohly pouze přirozen&eacute; a n&aacute;hodn&eacute; př&iacute;činy. Autoři poukazuj&iacute; na to, že zřejmě sehr&aacute;ly rozhoduj&iacute;c&iacute; roli extern&iacute; vlivy, a to hlavně zv&yacute;&scaron;en&eacute; koncentrace sklen&iacute;kov&yacute;ch plynů, lidmi vypou&scaron;těn&yacute;ch aerosolů či sopečn&eacute; erupce.</p>
    <hr /><h2>M&eacute;ně antropogenn&iacute;ch aerosolů a erupc&iacute; vede k vět&scaron;&iacute; četnosti hurik&aacute;nů</h2>
    <p>Tyto faktory působ&iacute; v každ&eacute;m regionu jin&yacute;m způsobem, jinak intenzivně. Proto jsou i změny v četnosti tropick&yacute;ch cykl&oacute;n v regionech různorod&eacute;.</p>
    <p>Např&iacute;klad za n&aacute;růst počtu hurik&aacute;nů v severn&iacute;m Atlantiku pravděpodobně může m&eacute;ně aerosolů antropogenn&iacute;ho i sopečn&eacute;ho původu v t&eacute;to oblasti. Tyto aerosoly slouž&iacute; jako kondenzačn&iacute; č&aacute;stice a podporuj&iacute; tvorbu mraků. Když je jich m&eacute;ně, tvoř&iacute; se i m&eacute;ně oblačnosti a paprsky tak mohou intenzivněji zahř&iacute;vat povrch oce&aacute;nu. Pokud z&aacute;roveň nen&iacute; dostatečně prohř&aacute;t&aacute; i horn&iacute; č&aacute;st atmosf&eacute;ry, doch&aacute;z&iacute; vlivem vznikl&eacute;ho rozd&iacute;lu v teplot&aacute;ch k vět&scaron;&iacute; nestabilitě atmosf&eacute;ry. A při takov&eacute; nestabilitě je vznik tropick&eacute; cykl&oacute;ny pravděpodobněj&scaron;&iacute;.</p>
    <hr /><h2>CO2 zmen&scaron;uje počet tropick&yacute;ch cykl&oacute;n, ale mohou b&yacute;t intenzivněj&scaron;&iacute;</h2>
    <p>Z dlouhodob&eacute;ho hlediska se v&scaron;ak i v oblasti severn&iacute;ho Atlantiku oček&aacute;v&aacute; pokles četnosti tropick&yacute;ch cykl&oacute;n. A to zejm&eacute;na vlivem CO2, oxidu uhličit&eacute;ho. Stoupaj&iacute;c&iacute; koncentrace sklen&iacute;kov&yacute;ch plynů zahř&iacute;vaj&iacute; mimo oce&aacute;nů pr&aacute;vě i horn&iacute; vrstvy atmosf&eacute;ry, což přin&aacute;&scaron;&iacute; vět&scaron;&iacute; stabilitu atmosf&eacute;ry a v důsledku m&eacute;ně tropick&yacute;ch cykl&oacute;n.</p>
    <p>V budoucnu se vlivem stoupaj&iacute;c&iacute;ch koncentrac&iacute; oček&aacute;v&aacute;, že dojde ke glob&aacute;ln&iacute;mu poklesu počtu tropick&yacute;ch cykl&oacute;n. A to za předpokladu, že nedojde k v&yacute;razn&yacute;m sopečn&yacute;m erupc&iacute;m, kter&eacute; by tento trend mohly alespoň č&aacute;stečně zvr&aacute;tit.</p>
    <p>M&eacute;ně tropick&yacute;ch cykl&oacute;n ale nutně neznamen&aacute; m&eacute;ně &scaron;kod. Tropick&eacute; cykl&oacute;ny, kter&eacute; budou v budoucnu vznikat, mohou b&yacute;t v&yacute;znamně intenzivněj&scaron;&iacute; a destruktivněj&scaron;&iacute;. Hlavn&iacute;m důvodem je vy&scaron;&scaron;&iacute; teplota oce&aacute;nu, kter&aacute; zjednodu&scaron;eně funguje pro takov&yacute; cykl&oacute;n jako z&aacute;sobn&iacute;k energie.</p>
    <hr /><h2>Metadata a koment&aacute;ř autorky</h2>
    <p>Informace pro čl&aacute;nek a video jsem čerpala z tohoto vědeck&eacute;ho čl&aacute;nku:</p>
    <p><em>Murakami, H. et al. (2020): Climate change has been influencing where tropical cyclones rage. PNAS, <a href="https://www.pnas.org/content/117/20/10706">DOI: 10.1073/pnas.1922500117</a></em></p>
    <p>Pro celkov&eacute; vyzněn&iacute; a interpretaci jsem se pak inspirovala na t&eacute;to str&aacute;nce:</p>
    <p><em>NOAA Headquarters (2020): Climate change has been influencing where tropical cyclones rage.&nbsp;ScienceDaily, <a href="http://www.sciencedaily.com/releases/2020/05/200504155207.htm">www.sciencedaily.com/releases/2020/05/200504155207.htm</a></em><em>(citov&aacute;no 22. 10. 2020)</em></p>
    <p>Pokud jste na&scaron;li chybu či nepřesnost, nev&aacute;hejte a <a href="https://www.facebook.com/zemepisec">kontaktujte mě</a>! Nejde o &uacute;mysl :) Z m&eacute; strany jde o experiment&aacute;ln&iacute; form&aacute;t. Pokud se v&aacute;m l&iacute;b&iacute;, můžete mi d&aacute;t vědět, popř&iacute;padě zasd&iacute;let. Uděl&aacute; mi to radost. Mějte se pěkně!</p>
    <hr />
    <p>Napsala Michaela Vojtěchovská, 22. října, 2020. <Link to="/blog/">Zobrazit další články na blogu</Link>.</p>

  </Layout>
)
